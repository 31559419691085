<template>
    <v-main class="ca-error-background ca-box-center text-center">
        <v-container>
            <img :src="$config.dados.logowhite" 
                :style="'width: 250px; margin: 10px auto'"
                class=" animate__animated animate__fadeInUp"
            />
            <v-main class="animate__animated animate__fadeInUp animate__delay-1s">
                <h2 :style="'color: white; font-size: 20px;'" v-once v-html="$t_('pagina_nao_encontrada')"></h2>
                <v-btn to="/" class="mt-4" v-once>
                    {{$t_('continuar_navegando')}}
                </v-btn>
            </v-main>
        </v-container>
    </v-main>
</template>

<script>

    export default {
        name: "Error",
        data: () => ({}),
    }

</script>

<style scoped>
    .ca-error-background {
        background-image: url('https://tourmaracana.s3.amazonaws.com/TourMaracana_bg2_4033x2035.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .ca-box-center {
        width: 100%; 
        height: 100vh; 
        display: flex; 
        justify-content: center; 
        align-items: center; 
        position: absolute; 
        z-index: 9999999999;
        
    }
    @media screen and (max-width: 960px) {
        .ca-error-background {
            background-image: url('https://tourmaracana.s3.amazonaws.com/TourMaracana_bg2_4033x2035.jpg');
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        
    }
</style>
